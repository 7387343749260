
import GenericMixin from "@/lib/mixins/GenericMixin";
import TasqFeedbackMixin from "@/lib/mixins/TasqFeedbackMixin";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";
import tasqsListModule from "@/store/modules/tasqsListModule";
import { getComponent, sleep } from "@/utils/helpers";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TasqWellAction: () => getComponent("tasqs/TasqWellAction"),
    TasqWellActionFeedback: () => getComponent("tasqs/TasqWellActionFeedback"),
    TasqWellFinalStepFeedback: () => getComponent("tasqs/TasqWellFinalStepFeedback"),
  },
})
export default class TasqBatchFeedback extends mixins(TasqFeedbackMixin, GenericMixin) {
  get isBatchResponding() {
    return tasqsListModule.isBatchResponding;
  }

  backHandler() {
    tasqFeedbackModule.setStepper(1);
  }

  async nextHandler() {
    if (this.feedbackStepNo === 1) {
      this.handleFormResponse({ markAsComplete: false,isBatchMode: true  });
    } else if (this.feedbackStepNo === 2) {
      // @ts-ignore
      const isValidForm = await this.$refs.tasqWellActionFeedback.submitInput();
      if (isValidForm) {
        this.handleFormResponse({ markAsComplete: true, isBatchMode: true });
      }
    }
  }
}
